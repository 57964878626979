import React from "react";
import { SMAR_USER_GUIDE_URL } from "../constants/externalLinks";
import "../css/MobileScreen.css";

const MobileScreen = () => {
  return (
    <div className="mobile-container">
      <div className="mobile-header">Website Unavailable on Mobile Devices</div>
      <div className="mobile-text">
        It looks like you're on a mobile device. Please use a PC or laptop for
        the best experience. Our app is optimized for larger screens.
      </div>
      <div className="mobile-image">
        <svg
          width="305"
          height="150"
          viewBox="0 0 305 150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_919_4688)">
            <path
              d="M0 135.36C0 135.665 0.343581 135.907 0.773859 135.907H304.226C304.653 135.907 305 135.665 305 135.36C305 135.056 304.656 134.814 304.226 134.814H0.773859C0.346792 134.814 0 135.056 0 135.36Z"
              fill="#2F2E43"
            />
            <path
              d="M253.412 136.134H52.2307V5.60025C52.2307 2.5138 54.7225 0 57.789 0H247.853C250.917 0 253.412 2.51057 253.412 5.60025V136.134Z"
              fill="#2F2E41"
            />
            <path
              d="M270.157 150H34.9843C34.1719 150 33.5072 149.492 33.2857 148.703C33.0673 147.916 33.3724 147.13 34.0659 146.706L52.3977 135.438H253.479L253.537 135.474L271.108 146.723C271.785 147.156 272.081 147.939 271.856 148.719C271.631 149.495 270.966 150 270.164 150H270.157Z"
              fill="#DFDFDF"
            />
            <path
              d="M247.905 8.32104H57.5063V127.583H247.905V8.32104Z"
              fill="white"
            />
            <path
              d="M255.245 142.837H51.0842L61.1765 134.587H245.612L255.245 142.837Z"
              fill="#2F2E41"
            />
            <path
              d="M201.11 148.382H104.304L107.974 142.837H197.44L201.11 148.382Z"
              fill="#2F2E41"
            />
            <path
              d="M209.77 55.4817H199.684C198.808 55.4817 198.095 54.7635 198.095 53.8803C198.095 52.997 198.808 52.2788 199.684 52.2788H209.77C210.647 52.2788 211.36 52.997 211.36 53.8803C211.36 54.7635 210.647 55.4817 209.77 55.4817Z"
              fill="#DFDFDF"
            />
            <path
              d="M221.369 55.4817H216.661C215.785 55.4817 215.072 54.7635 215.072 53.8803C215.072 52.997 215.785 52.2788 216.661 52.2788H221.369C222.245 52.2788 222.958 52.997 222.958 53.8803C222.958 54.7635 222.245 55.4817 221.369 55.4817Z"
              fill="#DFDFDF"
            />
            <path
              d="M108.79 56.7045H78.0922C76.5253 56.7045 75.2505 55.42 75.2505 53.8412C75.2505 52.2624 76.5253 50.978 78.0922 50.978H108.79C110.357 50.978 111.631 52.2624 111.631 53.8412C111.631 55.42 110.357 56.7045 108.79 56.7045Z"
              fill="#2196F3"
            />
            <path
              d="M92.1471 64.343H77.3538C76.4002 64.343 75.6199 63.56 75.6199 62.5959C75.6199 61.6318 76.3969 60.8489 77.3538 60.8489H92.1471C93.1007 60.8489 93.881 61.6318 93.881 62.5959C93.881 63.56 93.104 64.343 92.1471 64.343Z"
              fill="#DFDFDF"
            />
            <path
              d="M173.701 71.2373H76.9813C76.0276 71.2373 75.2473 70.4543 75.2473 69.4902C75.2473 68.5261 76.0244 67.7432 76.9813 67.7432H173.701C174.654 67.7432 175.435 68.5261 175.435 69.4902C175.435 70.4543 174.658 71.2373 173.701 71.2373Z"
              fill="#DFDFDF"
            />
            <path
              d="M165.381 75.8962H76.9813C76.0276 75.8962 75.2473 75.1133 75.2473 74.1491C75.2473 73.185 76.0244 72.4021 76.9813 72.4021H165.378C166.332 72.4021 167.112 73.185 167.112 74.1491C167.112 75.1133 166.335 75.8962 165.378 75.8962H165.381Z"
              fill="#DFDFDF"
            />
            <path
              d="M269.322 49.2343L264.596 50.8002V43.9414H268.886L269.322 49.2343Z"
              fill="#F2A7AA"
            />
            <path
              d="M265.18 45.8404C267.782 45.8404 269.891 43.7155 269.891 41.0943C269.891 38.4731 267.782 36.3481 265.18 36.3481C262.578 36.3481 260.469 38.4731 260.469 41.0943C260.469 43.7155 262.578 45.8404 265.18 45.8404Z"
              fill="#F2A7AA"
            />
            <path
              d="M266.474 40.6254C265.684 40.6027 265.167 39.7971 264.862 39.066C264.557 38.3316 264.239 37.4904 263.51 37.1863C262.913 36.9372 261.857 38.6098 261.385 38.1666C260.893 37.7039 261.372 35.3163 261.895 34.8892C262.419 34.4622 263.131 34.3781 263.806 34.3457C265.447 34.2681 267.1 34.404 268.709 34.7404C269.704 34.9507 270.729 35.2645 271.448 35.9892C272.357 36.908 272.591 38.296 272.655 39.5933C272.723 40.9198 272.646 42.3109 272.007 43.4692C271.368 44.6306 270.025 45.488 268.741 45.1903C268.613 44.485 268.741 43.7636 268.792 43.0486C268.841 42.3336 268.792 41.5636 268.359 40.9942C267.925 40.4248 267.001 40.2016 266.497 40.7063"
              fill="#36344E"
            />
            <path
              d="M272.27 42.2687C272.742 41.9193 273.304 41.6314 273.885 41.7025C274.511 41.7802 275.041 42.3011 275.202 42.9158C275.362 43.5305 275.182 44.2099 274.794 44.7113C274.405 45.2128 273.831 45.546 273.22 45.7143C272.867 45.8113 272.482 45.8502 272.145 45.7046C271.65 45.491 271.384 44.8505 271.576 44.3458"
              fill="#36344E"
            />
            <path
              d="M267.762 125.528H263.334V131.857H267.762V125.528Z"
              fill="#F2A7AA"
            />
            <path
              d="M259.021 135.69C258.556 135.69 258.141 135.677 257.83 135.648C256.655 135.538 255.531 134.665 254.966 134.154C254.712 133.924 254.632 133.558 254.763 133.241C254.857 133.015 255.046 132.846 255.28 132.779L258.389 131.882L263.42 128.459L263.475 128.56C263.497 128.599 263.989 129.495 264.156 130.1C264.22 130.329 264.201 130.524 264.108 130.672C264.04 130.776 263.95 130.834 263.873 130.87C263.963 130.967 264.249 131.161 265.129 131.303C266.41 131.51 266.683 130.168 266.692 130.113L266.702 130.067L266.741 130.042C267.351 129.644 267.726 129.466 267.858 129.504C267.938 129.53 268.073 129.569 268.439 133.222C268.475 133.335 268.731 134.176 268.558 134.979C268.369 135.852 264.58 135.551 263.822 135.483C263.799 135.483 260.964 135.69 259.018 135.69H259.021Z"
              fill="#36344E"
            />
            <path
              d="M280.369 120.552L276.611 122.913L279.935 128.282L283.692 125.921L280.369 120.552Z"
              fill="#F2A7AA"
            />
            <path
              d="M275.382 134.823C274.861 134.823 274.383 134.762 274.043 134.7C273.709 134.642 273.445 134.374 273.391 134.034C273.352 133.791 273.423 133.549 273.587 133.364L275.754 130.947L278.226 125.36L278.326 125.415C278.365 125.435 279.254 125.933 279.713 126.36C279.887 126.522 279.973 126.693 279.97 126.871C279.97 126.994 279.922 127.094 279.877 127.162C280.005 127.195 280.349 127.207 281.171 126.858C282.369 126.35 281.893 125.066 281.874 125.011L281.858 124.969L281.877 124.927C282.189 124.263 282.41 123.911 282.542 123.875C282.622 123.852 282.76 123.817 284.986 126.719C285.076 126.797 285.734 127.372 286.01 128.149C286.309 128.99 282.934 130.757 282.256 131.103C282.234 131.119 278.705 133.704 277.257 134.438C276.682 134.729 276.001 134.82 275.382 134.82V134.823Z"
              fill="#36344E"
            />
            <path
              d="M271.024 72.593H258.626L257.502 84.2141L262.425 127.149H268.754L266.224 102.351L276.489 124.739L282.115 120.773L274.097 99.8728C274.097 99.8728 276.961 81.6647 274.71 77.1289C272.459 72.593 271.024 72.593 271.024 72.593Z"
              fill="#36344E"
            />
            <path
              d="M277.189 73.3015H257.499L263.408 47.6555H271.984L277.189 73.3015Z"
              fill="#2196F3"
            />
            <path
              d="M252.609 47.1475C252.294 45.5881 252.869 44.1614 253.893 43.964C254.918 43.7667 256 44.8699 256.314 46.4325C256.449 47.0537 256.427 47.6975 256.253 48.3122L257.499 54.9413L254.282 55.4524L253.396 48.8654C252.997 48.364 252.728 47.7751 252.609 47.1475Z"
              fill="#F2A7AA"
            />
            <path
              d="M271.775 47.6555H264.21L259.111 57.934L257.188 50.234L252.962 50.687C252.962 50.687 253.961 65.7342 258.334 65.2263C262.704 64.7183 272.899 51.1593 271.779 47.6588L271.775 47.6555Z"
              fill="#2196F3"
            />
            <path
              d="M280.227 86.7407C280.542 88.3001 279.967 89.7269 278.942 89.9242C277.918 90.1216 276.836 89.0184 276.521 87.4557C276.386 86.8345 276.409 86.1907 276.582 85.576L275.336 78.947L278.554 78.4358L279.44 85.0228C279.838 85.5243 280.108 86.1131 280.227 86.7407Z"
              fill="#F2A7AA"
            />
            <path
              d="M267.463 47.6554C267.463 47.6554 271.763 47.471 271.984 47.6554C273.156 48.6228 280.041 83.4569 280.041 83.4569H275.68L267.46 47.6554H267.463Z"
              fill="#2196F3"
            />
          </g>
          <defs>
            <clipPath id="clip0_919_4688">
              <rect width="305" height="150" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="mobile-info">
        For more information about the SMAR Web Application, check out our{" "}
        <a href={SMAR_USER_GUIDE_URL}>user guide</a>!
      </div>
    </div>
  );
};

export default MobileScreen;
